function displayPlaceOrderModal() {
	document.getElementById('place-my-order-modal').classList.add('is-active');

	// get teh checked radio .shipping_method
	const checkedShippingMethod = document.querySelector(
		'.shipping_method:checked',
	);

	const checkedShippingMethodId = checkedShippingMethod.getAttribute('id');

	const checkedShippingMethodLabel = document.querySelector(
		`label[for="${checkedShippingMethodId}"]`,
	).innerText;

	// check if has the string Click & Collect
	var option = 'Hand Delivery';
	var fromTo = 'to';
	var address = false;
	if (checkedShippingMethodLabel.includes('Click & Collect')) {
		option = 'Click & Collect';
		fromTo = 'from';

		// address is checkedShippingMethodLabel removing Click & Collect
		address = checkedShippingMethodLabel.replace('Click & Collect - ', '');
	} else {
		var type = 'billing';

		if (
			document.getElementById('ship-to-different-address-checkbox')
				.checked
		) {
			type = 'shipping';
		}

		var streetAddress = document.getElementById(type + '_address_1').value;
		var city = document.getElementById(type + '_city').value;
		var postcode = document.getElementById(type + '_postcode').value;
		var state = document.getElementById(type + '_state').value;

		address = streetAddress + ', ' + city + ', ' + postcode + ', ' + state;
	}

	document.getElementById('place-my-order-option').innerText = option;
	document.getElementById('place-my-order-from-to').innerText = fromTo;
	document.getElementById('place-my-order-address').innerText = address;
}

// access wordpress jquery
var $ = jQuery;

// // Hide popup if form returns an error
// $(document.body).on('checkout_error', function () {
// 	var errors = $('.woocommerce-error').find('li');

// 	// check if one of the error is custom_notice
// 	var customNotice = false;

// 	errors.each(function () {
// 		if ($(this).text().trim() == 'custom_notice') {
// 			customNotice = true;
// 			$(this).remove();
// 			return false;
// 		}
// 	});

// 	// count number of errors
// 	if (errors.length == 1 && customNotice) {
// 		$('.woocommerce-error').remove();
// 		displayPlaceOrderModal();
// 	}
// });

$('#btn-custom-place-order').on('click', function () {
	$('#m_prevent_submit').remove();
	$('#place_order').trigger('click');
	document
		.getElementById('place-my-order-modal')
		.classList.remove('is-active');
});

$('#btn-hide-place-order-modal').on('click', function () {
	document
		.getElementById('place-my-order-modal')
		.classList.remove('is-active');
});
