const formLogin = () => {
	const buttons = document.querySelectorAll('.btn-select-login');

	if (buttons) {
		buttons.forEach((btn) => {
			btn.addEventListener('click', function (e) {
				e.preventDefault();

				const addClassName = e.currentTarget.dataset.add;
				const removeClassName = e.currentTarget.dataset.remove;

				const loginFormContainer = document.getElementById(
					'login-form-container',
				);

				loginFormContainer.classList.add(addClassName);
				loginFormContainer.classList.remove(removeClassName);

				document
					.querySelector('.btn-select-login.active')
					.classList.remove('active');
				e.currentTarget.classList.add('active');
			});
		});
	}
};

export default formLogin;
