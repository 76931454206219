const multipleLocation = () => {
	const existMultipleLocation = document.querySelector(
		'.block-mulple-locations',
	);

	if (!existMultipleLocation) return;

	const multipleLocationItems = document.querySelectorAll(
		'.multiple-location-item',
	);

	const multipleLocationTrigger = document.querySelectorAll(
		'.multiple-location-trigger',
	);

	const multipleLocationMaps = document.querySelectorAll(
		'.multiple-location-map',
	);

	multipleLocationTrigger.forEach((item) => {
		item.addEventListener('click', () => {
			let pos = item.dataset.pos;

			// Remove active class from all items
			multipleLocationItems.forEach((item) => {
				item.classList.remove('active');
			});
			multipleLocationMaps.forEach((item) => {
				item.classList.remove('active');
			});

			// Add active class to corresponding item
			document
				.getElementById(`multiple-location-item-${pos}`)
				.classList.add('active');

			// Add active class to corresponding map
			document
				.getElementById(`multiple-location-map-${pos}`)
				.classList.add('active');
		});
	});
};

export default multipleLocation;
