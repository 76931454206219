// import ionRangeSlider from 'ion-rangeslider';

const rangeSliderPrice = () => {
	jQuery(document).ready(function ($) {
		const priceRange = document.getElementById('price-range');

		if (priceRange) {
			const min = priceRange.getAttribute('data-min');
			const max = priceRange.getAttribute('data-max');
			let from = priceRange.getAttribute('data-from');
			let to = priceRange.getAttribute('data-to');

			if (from === '') {
				from = min;
			}

			if (to === '') {
				to = max;
			}

			$(priceRange).ionRangeSlider({
				type: 'double',
				min: min,
				max: max,
				from: min,
				to: max,
				prefix: '$',
			});
		}
	});
};

export default rangeSliderPrice;
