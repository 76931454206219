import quantityInputs from '../components/quantity-input.js';

const observeMutations = () => {
	const targetNode = document.querySelector('.woocommerce');
	const config = {childList: true, subtree: true};

	const callback = function (mutationsList, observer) {
		for (let mutation of mutationsList) {
			if (mutation.type === 'childList') {
				quantityInputs();
				break;
			}
		}
	};

	const observer = new MutationObserver(callback);

	if (targetNode) {
		observer.observe(targetNode, config);
	}
};

document.addEventListener('DOMContentLoaded', () => {
	// check if is single product page
	quantityInputs();
	if (!document.querySelector('body.single-product')) {
		observeMutations();
	}
});
