import axios from 'axios';
import {setCookie, getCookie} from './cookie.js';

let autocompleteInstance;
let autocompleteSessionToken;
let $loading = false;
let $unavailableDeliveryMessage = false;

const axiosInstance = axios.create({
	baseURL: window?.sage?.AJAX_URL,
	withCredentials: true,
});

window.addEventListener('DOMContentLoaded', () => {
	// Delivery method
	const $checkoutPage = document.querySelector(
		'#select-form-method-shipping',
	);
	// Autopopulate delivery location
	autoPopulateDeliveryLocation();

	if (!$checkoutPage) {
		return;
	}

	const deliveryBtn = document.querySelector(
		'#select-form-method-shipping-delivery-btn',
	);

	$loading = document.querySelector(
		'#select-form-method-shipping-delivery-loading',
	);
	$unavailableDeliveryMessage = document.querySelector(
		'#select-form-method-shipping-delivery-unavailable',
	);

	deliveryBtn?.addEventListener('click', () => {
		initGoogleMapPlaces();

		document
			.querySelector('#select-form-method-shipping-delivery')
			.classList.add('is-active');
	});

	document
		.querySelector(
			'#select-form-method-shipping-delivery-unavailable-close',
		)
		.addEventListener('click', () => {
			document
				.querySelector('#select-form-method-shipping-delivery')
				.classList.remove('is-active');

			document
				.querySelector('#select-form-method-shipping-pickup')
				.classList.add('is-active');
		});

	// Pickup method
	const pickupBtn = document.querySelector(
		'#select-form-method-shipping-pickup-btn',
	);
	const pickupUserLocationBtn = document.querySelector(
		'#select-form-method-shipping-pickup-use-location',
	);

	pickupBtn.addEventListener('click', () => {
		document
			.querySelector('#select-form-method-shipping-pickup')
			.classList.add('is-active');
	});

	pickupUserLocationBtn.addEventListener('click', () => {
		// Get user's location
		if ('geolocation' in navigator) {
			document
				.querySelectorAll('.location-icon-arrow-right')
				.forEach((el) => {
					el.classList.add('hidden');
				});

			document.querySelectorAll('.location-info').forEach((el) => {
				el.classList.remove('hidden');
			});

			navigator.geolocation.getCurrentPosition(
				(position) => {
					const {latitude, longitude} = position.coords;
					// const origin = `${latitude},${longitude}`;
					const origin = '-27.487783,153.0493235';

					document
						.querySelectorAll('.location-info')
						.forEach((el) => {
							const destination =
								el.getAttribute('data-location');

							getDistanceFromUserToLocation(
								origin,
								destination,
							).then((response) => {
								if (response.data?.data?.distance !== null) {
									const distance =
										response.data.data.distance;
									const duration =
										response.data.data.duration;
									el.querySelector(
										'.location-distance',
									).textContent = distance;
									el.querySelector(
										'.location-duration',
									).textContent = duration;
								} else {
									alert(
										'Your locations is outside of Australia',
									);
								}

								// check if distance exists

								el.querySelector(
									'.location-distance',
								).classList.remove('hidden');
								el.querySelector(
									'.location-duration',
								).classList.remove('hidden');

								el.querySelectorAll(
									'.location-placeholder-skeleton',
								)?.forEach((el) => {
									el.classList.add('hidden');
								});
							});
						});
				},
				(error) => {
					console.error('Geolocation error:', error.message);
				},
				{
					enableHighAccuracy: true,
					timeout: 10000,
					maximumAge: 0,
				},
			);
		} else {
			alert('Geolocation is not supported by this browser.');
		}
	});

	document
		.querySelectorAll('.selelct-form-method-shipping-pickup-location')
		.forEach((el) => {
			el.addEventListener('click', () => {
				const locationId = el.getAttribute('data-id');
				setCookie('pickup_location_id', locationId, 1);
				location.reload();
			});
		});
});

// Delivery
const initGoogleMapPlaces = () => {
	const inputField = document.getElementById(
		'select-form-method-shipping-delivery-search',

		debounce((event) => {
			console.log('User finished typing:', event.target.value);
		}, 400),
	);

	if (!inputField) return;

	// Only initialize once
	if (!autocompleteInstance) {
		autocompleteSessionToken =
			new google.maps.places.AutocompleteSessionToken();

		autocompleteInstance = new google.maps.places.Autocomplete(inputField, {
			componentRestrictions: {country: 'au'},
			fields: ['address_components', 'geometry'],
			sessionToken: autocompleteSessionToken,
		});

		autocompleteInstance.addListener('place_changed', () => {
			const place = autocompleteInstance.getPlace();

			$loading.classList.remove('hidden');
			$unavailableDeliveryMessage.classList.add('hidden');

			checkIfPlaceHasDeliveryOption(place)
				.then((response) => {
					console.log('response', response);
					if (response.data.data.location_id) {
						setCookie(
							'delivery_location_id',
							response.data.data.location_id,
							1,
						);

						setWoocommerceBillingAddressCookie(place);

						location.reload();
					} else {
						$unavailableDeliveryMessage.classList.remove('hidden');
						$loading.classList.add('hidden');
					}
				})
				.catch((error) => {
					console.error('Failed to check delivery option:', error);
					// Show error message to user
					$loading.classList.add('hidden');
				})
				.finally(() => {});
		});
	}
};
const checkIfPlaceHasDeliveryOption = async (place) => {
	const postCode = place.address_components.find((component) =>
		component.types.includes('postal_code'),
	);

	const formData = new FormData();
	formData.append('action', 'check_delivery_option');
	formData.append('postcode', postCode?.long_name || '');

	return axiosInstance.post('', formData);
};

const setWoocommerceBillingAddressCookie = (place) => {
	const components = place.address_components;

	const streetNumber = getAddressComponent(components, 'street_number');
	const route = getAddressComponent(components, 'route');
	const suburb = getAddressComponent(components, 'locality');
	const postcode = getAddressComponent(components, 'postal_code');
	const state = getAddressComponent(
		components,
		'administrative_area_level_1',
	);

	const address1 = `${streetNumber} ${route}`.trim();

	const addressData = {
		billing_address_1: address1,
		billing_city: suburb,
		billing_postcode: postcode,
		billing_state: state,
	};

	// Save as a single cookie (7 days)
	setCookie('billing_address_data', JSON.stringify(addressData), 1);
};

const autoPopulateDeliveryLocation = () => {
	if (!document.querySelector('#billing_address_1')) {
		return;
	}

	const addressData = getCookie('billing_address_data');
	const deliveryLocation = getCookie('delivery_location_id');

	if (!addressData || !deliveryLocation) {
		return;
	}
	const decoded = decodeURIComponent(addressData);
	const {billing_address_1, billing_city, billing_postcode, billing_state} =
		JSON.parse(decoded);

	const stateMap = {
		Queensland: 'QLD',
		'New South Wales': 'NSW',
		Victoria: 'VIC',
		Tasmania: 'TAS',
		'South Australia': 'SA',
		'Western Australia': 'WA',
		'Northern Territory': 'NT',
		'Australian Capital Territory': 'ACT',
	};

	document.querySelector('#billing_address_1').value = billing_address_1;
	document.querySelector('#billing_city').value = billing_city;
	document.querySelector('#billing_postcode').value = billing_postcode;
	document.querySelector('#billing_state').value =
		stateMap[billing_state] || billing_state;

	[
		'#billing_address_1',
		'#billing_city',
		'#billing_postcode',
		'#billing_state',
	].forEach((selector) => {
		const el = document.querySelector(selector);
		if (el) {
			el.dispatchEvent(new Event('change', {bubbles: true}));
		}
	});
};

// Pickup
const getDistanceFromUserToLocation = async (origin, destination) => {
	const formData = new FormData();
	formData.append('action', 'get_distance_from_user_to_location');
	formData.append('origin', origin);
	formData.append('destination', destination);

	return axiosInstance.post('', formData);
};

// helpers
function getAddressComponent(components, type) {
	return components.find((c) => c.types.includes(type))?.long_name || '';
}

function debounce(func, delay = 300) {
	let timeout;
	return function (...args) {
		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(this, args), delay);
	};
}
