const shopFitler = () => {
	const $btnApplyFilter = document.getElementById(
		'btn-woocommerce-shop-apply-filters',
	);

	const $checkboxAttribute = document.querySelectorAll(
		'.btn-woocommerce-shop-attribute',
	);

	const $selectCategory = document.querySelectorAll(
		'.btn-woocommerce-shop-category',
	);

	// Category
	if ($selectCategory) {
		$selectCategory.forEach((btn) => {
			btn.addEventListener('click', function (e) {
				e.preventDefault();

				$selectCategory.forEach((btn) => {
					btn.classList.remove('checked');
				});

				this.classList.add('checked');
			});
		});
	}

	// Attribute filter
	if ($checkboxAttribute) {
		$checkboxAttribute.forEach((btn) => {
			btn.addEventListener('click', function (e) {
				e.preventDefault();

				if (this.classList.contains('checked')) {
					this.classList.remove('checked');
				} else {
					this.classList.add('checked');
				}
			});
		});
	}

	// Apply filters
	if ($btnApplyFilter) {
		$btnApplyFilter.addEventListener('click', function (e) {
			e.preventDefault();

			// Price filter
			const priceRange = document.getElementById('price-range');
			const priceRangeMin = priceRange.getAttribute('data-min');
			const priceRangeMax = priceRange.getAttribute('data-max');
			const [minPrice, maxPrice] = priceRange.value.split(';');

			const urlParams = new URLSearchParams(window.location.search);

			if (minPrice == priceRangeMin && maxPrice == priceRangeMax) {
				urlParams.delete('min_price');
				urlParams.delete('max_price');
			} else {
				urlParams.set('min_price', minPrice);
				urlParams.set('max_price', maxPrice);
			}

			// Attribute filter
			const $attributeFilter = document.querySelectorAll(
				'.btn-woocommerce-shop-attribute.checked',
			);

			urlParams.delete('dietary');
			urlParams.delete('cocoa-content');
			urlParams.delete('chocolate-type');

			var attributes = {};

			if ($attributeFilter.length) {
				$attributeFilter.forEach((btn) => {
					const attribute = btn.getAttribute('data-attribute');
					const value = btn.getAttribute('data-value');

					if (attributes[attribute]) {
						attributes[attribute].push(value);
					} else {
						attributes[attribute] = [value];
					}
				});

				for (const [key, value] of Object.entries(attributes)) {
					urlParams.set(key, value.join(','));
				}
			}

			// Category filter
			// const $categoryFilter = document.querySelector(
			// 	'.btn-woocommerce-shop-category.checked',
			// );
			// if ($categoryFilter) {
			// 	const category = $categoryFilter.getAttribute('data-value');
			// 	urlParams.set('category', category);
			// } else {
			// 	urlParams.delete('category');
			// }

			// Apply
			const form = this.form;
			window.location.href = form.action + '?' + urlParams.toString();
		});
	}

	//
	// Mobile
	//

	const html = document.querySelector('html');
	const $btnApplyFilterMobile = document.querySelector('.open-shop-filters');
	const $btnsCloseFilterMobile = document.querySelector(
		'.close-shop-filters',
	);
	const $containerShopFilter = document.getElementById(
		'woocommerce-shop-filter-container',
	);

	if ($btnApplyFilterMobile) {
		$btnApplyFilterMobile.addEventListener('click', function (e) {
			e.preventDefault();

			$containerShopFilter.classList.add('active');

			html.classList.add('no-scroll');
		});
	}

	if ($btnsCloseFilterMobile) {
		$btnsCloseFilterMobile.addEventListener('click', function (e) {
			e.preventDefault();

			$containerShopFilter.classList.remove('active');

			html.classList.remove('no-scroll');
		});
	}

	// Sort by
	const $selectSortBy = document.getElementById('woocommerce-shop-sort');

	if ($selectSortBy) {
		$selectSortBy.addEventListener('change', function (e) {
			const urlParams = new URLSearchParams(window.location.search);
			urlParams.set('orderby', this.value);

			const form = this.form;
			window.location.href = form.action + '?' + urlParams.toString();
		});
	}
};

export default shopFitler;
