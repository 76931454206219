import domReady from '@roots/sage/client/dom-ready';
import accordion from './components/accordion.js';
import progressBar from './components/progress-bar.js';
import scrollingLogos from './components/scrolling-logos.js';
import galleryHorizontalScroll from './components/gallery-horizontal-scroll.js';
import tabber from './components/tabber.js';
import formLogin from './components/form-login.js';
import multipleLocation from './components/multiple-location.js';
import shopFitler from './components/shop-filter.js';
import createModals from './components/modal.js';
import rangeSliderPrice from './components/range-slider.js';
import {flare} from '@flareapp/js';

import './common/cookie.js';
import './common/header.js';
import './common/single-product.js';
import './common/anchor.js';
import './common/objserve-mutations.js';
import './common/product-hamper.js';
import './common/checkout.js';
import './common/checkout-delivery.js';

/**
 * Application entrypoint
 */
domReady(async () => {
	accordion();
	progressBar();
	scrollingLogos();
	galleryHorizontalScroll();
	tabber();
	formLogin();
	multipleLocation();
	shopFitler();
	createModals();
	rangeSliderPrice();
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);

if (window.sage.WP_ENV === 'production') {
	flare.light(window.sage.FLARE_JS_KEY);
}
