// Acivate the responsive menu
var mainHeaderHeight = false;
var headerSpotlightBar = false;
var headerSpotlightBarHeight = 0;
var mobileMenu = false;
var mobileMegaMenu = false;

// Set header Height
function updateTotalHeaderHeightVar() {
	if (headerSpotlightBar) {
		headerSpotlightBarHeight = headerSpotlightBar?.offsetHeight;
	} else {
		headerSpotlightBarHeight = 0;
	}

	var totalHeaderHeight =
		headerSpotlightBarHeight + mainHeaderHeight?.offsetHeight;

	// Update the CSS variable in :root
	document.documentElement.style.setProperty(
		'--total-header-height',
		`${totalHeaderHeight}px`,
	);
}

function setMobileMenuHeight() {
	let headerTotalHeight = mainHeaderHeight?.offsetHeight + headerSpotlightBar;

	mobileMenu.style.height = `calc(100svh - ${headerTotalHeight}px)`;
}

function updateHeaderHeights() {
	if (mobileMenu && window.innerWidth < 1024) {
		setMobileMenuHeight();
	} else {
		updateTotalHeaderHeightVar();
	}
}

// Actions

window.addEventListener('DOMContentLoaded', () => {
	headerSpotlightBar = document.getElementById('header-spotlight-bar');
	mainHeaderHeight = document.getElementById('main-header');
	mobileMenu = document.getElementById('mobile-menu');
	mobileMegaMenu = document.querySelector('.mega-menu-wrap');
	var openMegaMenuSubmenu = document.querySelectorAll(
		'.open-mega-menu-submenu',
	);
	var closeMegaMenuSubmenu = document.querySelectorAll(
		'.close-mega-menu-submenu',
	);

	updateHeaderHeights();

	// Mobile Menu
	const menu = document.getElementById('header-menu');
	const menuClose = document.querySelector('.mobile-menu-close');
	const html = document.querySelector('html');
	const subMenuOpen = document.querySelectorAll('.mobile-menu-open-submenu');
	const subMenuClose = document.querySelectorAll(
		'.mobile-menu-close-submenu',
	);

	subMenuOpen?.forEach((button) => {
		button.addEventListener('click', () => {
			button.parentElement.classList.add('active');
		});
	});

	subMenuClose?.forEach((button) => {
		button.addEventListener('click', (e) => {
			e.preventDefault();
			button.parentElement.parentElement.classList.remove('active');
		});
	});

	menuClose?.addEventListener('click', () => {
		menu.classList.remove('active');
		mainHeaderHeight.classList.remove('active');
		html.classList.remove('no-scroll');
		mobileMenu?.classList.remove('active');
		mobileMegaMenu.classList.remove('active');
	});

	menu?.addEventListener('click', () => {
		menu.classList.toggle('active');
		mainHeaderHeight.classList.toggle('active');
		html.classList.toggle('no-scroll');
		mobileMenu?.classList.toggle('active');
		mobileMegaMenu?.classList.toggle('active');
	});

	if (openMegaMenuSubmenu.length) {
		openMegaMenuSubmenu.forEach((button) => {
			button.addEventListener('click', () => {
				const submenu = button.parentElement.nextElementSibling;
				submenu.classList.add('active');
				mobileMegaMenu.classList.add('submenu-active');
			});
		});
	}

	if (closeMegaMenuSubmenu.length) {
		closeMegaMenuSubmenu.forEach((button) => {
			button.addEventListener('click', (e) => {
				e.preventDefault();
				const submenu = button.parentElement;
				submenu.classList.remove('active');
				mobileMegaMenu.classList.remove('submenu-active');
			});
		});
	}
});
window.addEventListener('resize', updateHeaderHeights);
