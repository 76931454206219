var selectionContainer = document.getElementById('hamper-selection-container');
var selectionList = document.getElementById('hamper-selection-container__list');

window.addEventListener('DOMContentLoaded', () => {
	const $optionItem = document.querySelectorAll(
		'.product-hamper-modal-option-item',
	);

	if (!$optionItem) {
		return;
	}

	const $placeholderImage = document.getElementById(
		'hamper-placeholder-text',
	);

	const $btnSubmit = document.querySelector('.single_add_to_cart_button');

	$optionItem.forEach((item) => {
		item.addEventListener('click', () => {
			let img = item.querySelector(
				'.product-hamper-modal-option-item__image',
			);
			let imgSrc = img.getAttribute('src');
			let slotSrc = item.getAttribute('data-slot');
			let title = item.querySelector(
				'.product-hamper-modal-option-item__title',
			).innerText;
			let url = item.getAttribute('data-url');
			let id = item.getAttribute('data-id');
			let compoenentKey = item.getAttribute('data-component');
			let image = new Image();
			image.src = imgSrc;
			image.classList.add('object-cover', 'thumbnail');
			let imageSlot = new Image();
			imageSlot.src = slotSrc;
			imageSlot.classList.add('object-cover', 'thumbnail');

			let component = document.querySelector(
				`#product-hamper-component-loop-item-${compoenentKey}`,
			);

			let componentContent = component.querySelector(
				'.product-hamper-component-loop-item__content',
			);
			componentContent.innerHTML =
				'<div class="loader opacity-20"></div>';

			// image load
			image.onload = () => {
				componentContent.innerHTML = '';
				componentContent.appendChild(image);
			};

			let componentTitle = component.querySelector(
				'.product-hamper-component-loop-item__title',
			);
			componentTitle.innerText = title;

			document
				.querySelector('.component-modal.is-active')
				.classList.remove('is-active');

			// Slot
			let slot = document.querySelector(
				`#component-slot-${compoenentKey}`,
			);
			slot.innerHTML = '<div class="loader opacity-20"></div>';
			imageSlot.onload = () => {
				slot.innerHTML = '';
				slot.appendChild(imageSlot);
			};

			if (checkIfAllComponentsSelected()) {
				$btnSubmit.removeAttribute('disabled');
				setTimeout(() => {
					$btnSubmit.classList.add('active');
				}, 500);

				setTimeout(() => {
					$btnSubmit.classList.add('stable');
				}, 1700);
			}

			// URl
			let componentUrl = component.querySelector(
				'.product-hamper-component-loop-item__url',
			);
			componentUrl.innerText = url;

			// Set value to hidden input
			let hiddenId = document.querySelector(
				`input[name="product_hamper_components[${compoenentKey}][id]"]`,
			);
			let hiddenTitle = document.querySelector(
				`input[name="product_hamper_components[${compoenentKey}][title]"]`,
			);

			hiddenId.value = id;
			hiddenTitle.value = title;

			fillNextComponent(compoenentKey);

			$placeholderImage.remove();

			document.querySelector('html').classList.remove('no-scroll');

			updateHamperSelectionList();
		});
	});
});

function checkIfAllComponentsSelected() {
	const $component = document.querySelectorAll(
		'.product-hamper-component-loop-item',
	);
	let selected = true;
	$component.forEach((item) => {
		if (
			item.querySelector('.product-hamper-component-loop-item__title')
				.innerText.length == 0
		) {
			selected = false;
		}
	});
	return selected;
}

function fillNextComponent(currentKey) {
	let nextKey = parseInt(currentKey) + 1;
	let nextComponent = document.querySelector(
		`#product-hamper-component-loop-item-${nextKey}`,
	);

	if (nextComponent) {
		// trigger click
		if (
			nextComponent.querySelector(
				'.product-hamper-component-loop-item__title',
			).innerText.length == 0
		) {
			nextComponent
				.querySelector('.product-hamper-component-loop-item__content')
				.click();
		}
	}
}

function updateHamperSelectionList() {
	selectionContainer.classList.remove('hidden');

	var listItems = [];

	const $component = document.querySelectorAll(
		'.product-hamper-component-loop-item',
	);

	$component.forEach((item) => {
		let title = item.querySelector(
			'.product-hamper-component-loop-item__title',
		).innerText;

		if (title.length == 0) {
			return;
		}

		let url = item.querySelector(
			'.product-hamper-component-loop-item__url',
		).innerText;

		listItems.push(`
            <span class="hamper-selection-container__list-item flex justify-between items-center py-1 text-gray-500 text-sm">
                <span class="block mr-2">${title}</span>
                <a href="${url}" target="_blank" class="text-xs flex-shrink-0 whitespace-nowrap">View details</a>
            </span>
        `);
	});

	selectionList.innerHTML = listItems.join('');
}
